@font-face {
  font-family: "WanzlEtelka";
  font-weight: 300;
  src: url("/static/font/Wanzl_EtelkaLight.otf")
    format("opentype");
}

@font-face {
  font-family: "WanzlEtelka";
  font-weight: normal;
  src: url("/static/font/Wanzl_EtelkaMedium.otf")
    format("opentype");
}

@font-face {
  font-family: "WanzlEtelka";
  font-weight: bold;
  src: url("/static/font/Wanzl_EtelkaText-Bold.otf")
    format("opentype");
}
